footer {
    height: 50px;
}

.App-footer .tab-border {
    border-right: 2px solid;
}

.App-footer .tab-border:nth-child(4) {
    border-right: none;
}

.App-footer a:focus, .App-footer a:hover {
    outline: none;
    text-decoration: none;
}

.App-footer p {
    text-align: center;
    margin-bottom: 0px;
    color: black;
}