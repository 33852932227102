html, body {
    height: 100%;
    margin: 0;
}

body {
    overflow-x: hidden;
    padding-right: 0px !important;
    position: relative;
}

#root {
    overflow-x: hidden;
    position: relative;
}

.prevent_scrolling_display {
    padding-right: 16px;
}

@media (min-width: 2000px) {
    .prevent_scrolling_display {
        padding-right: 0px;
    }
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    .prevent_scrolling_display {
        padding-right: 0px;
    }
}}

@supports (overflow:-webkit-marquee) and (justify-content:inherit)
{
    .prevent_scrolling_display {
        padding-right: 0px;
    }
}

#root .row {
    margin: 0px;
}

.no-padding {
    padding: 0px !important;
}

@font-face {
    font-family: "silkamono-black";
    src: url("fonts/SilkaMono-Black.otf");
}

@font-face {
    font-family: "silkamono-blackItalic";
    src: url("fonts/SilkaMono-BlackItalic.otf");
}

@font-face {
    font-family: "silkamono-bold";
    src: url("fonts/SilkaMono-Bold.otf");
}

@font-face {
    font-family: "silkamono-boldItalic";
    src: url("fonts/SilkaMono-BoldItalic.otf");
}

@font-face {
    font-family: "silkamono-extraLight";
    src: url("fonts/SilkaMono-ExtraLight.otf");
}

@font-face {
    font-family: "silkamono-extraLightItalic";
    src: url("fonts/SilkaMono-ExtraLightItalic.otf");
}

@font-face {
    font-family: "silkamono-light";
    src: url("fonts/SilkaMono-Light.otf");
}

@font-face {
    font-family: "silkamono-lightItalic";
    src: url("fonts/SilkaMono-LightItalic.otf");
}

@font-face {
    font-family: "silkamono-medium";
    src: url("fonts/SilkaMono-Medium.otf");
}

@font-face {
    font-family: "silkamono-mediumItalic";
    src: url("fonts/SilkaMono-MediumItalic.otf");
}

@font-face {
    font-family: "silkamono-regular";
    src: url("fonts/SilkaMono-Regular.otf");
}

@font-face {
    font-family: "silkamono-regularItalic";
    src: url("fonts/SilkaMono-RegularItalic.otf");
}

@font-face {
    font-family: "silkamono-semiBold";
    src: url("fonts/SilkaMono-SemiBold.otf");
}

@font-face {
    font-family: "silkamono-semiBoldItalic";
    src: url("fonts/SilkaMono-SemiBoldItalic.otf");
}

@font-face {
    font-family: "silkamono-thin";
    src: url("fonts/SilkaMono-Thin.otf");
}

@font-face {
    font-family: "silkamono-thinItalic";
    src: url("fonts/SilkaMono-ThinItalic.otf");
}