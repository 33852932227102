
.img-carousel {
    /* height: 250px; */
    width: 100%;
    object-fit: contain;
}

.model-carousel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all .5s;
    opacity: 0;
    background-color:rgba(255, 255, 255, 0.5);
    margin: 0px 14px;
}

.model-carousel:hover {
    opacity: 1;
}

.model-carousel .infos {
    position: relative;
    text-align: center;
    top: 35%;
    /* transform: translateY(-50%); */
    transition-property: opacity;
    transition-delay: 0.2s;
    font-size: 15px;
    color: black;
}

/* .model, .scout {
    padding-bottom: 30px;
} */

.model-carousel .infos p:first-child {
    margin-bottom: 50px;
    font-weight: bold;
}

.model-carousel .infos p:not(:first-child) {
    margin-bottom: 0px;
}

.react-multi-carousel-list {
    z-index: 2;
}

.carousel_news, .react-multi-carousel-list {
    height: 100%;
}

.carousel_news .carousel-button-group {
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 98%;
    left: 50%;
    z-index: 1;
}

.carousel_news .carousel-button-group .custom-button-next:before, .carousel_news .carousel-button-group .custom-button-prev:before {
    content: "";
}

.carousel_news .carousel-button-group .custom-button-next {
    border-right: 1px solid;
    border-bottom: 1px solid;
    width: 15px;
    height: 15px;
    transform: rotate(-45deg);
    position: absolute;
    right: 3px;
}

.carousel_news .carousel-button-group .custom-button-prev {
    border-left: 1px solid;
    border-bottom: 1px solid;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    position: absolute;
    left: 3px;
}

.carousel_modal .carousel-button-group {
    position: absolute;
    top: 95%;
    transform: translate(-50%, -50%);
    width: 90%;
    left: 50%;
    z-index: 9;
    font-family: 'silkamono-regular';
}

.custom-button-next {
    float: right;
    margin-right: 0px;
}

.custom-button-prev {
    margin-left: 0px;
}

.custom-button-next, .custom-button-prev {
    background: none;
    border: none;
    padding: 0px;
}

.custom-button-next:focus, .custom-button-next:active, .custom-button-prev:focus, .custom-button-prev:active {
    outline: none;
}

.custom-button-next::before {
    content: ">";
    width: 15px;
    height: 15px;
    /* border-right: 1px solid black;
    border-bottom: 1px solid black;
    transform: rotate(-45deg);
    position: absolute;
    right: 5px;
    top: 0%; */
}

.custom-button-prev::before {
    content: "<";
    width: 15px;
    height: 15px;
    /* border-left: 1px solid black; */
    /* border-bottom: 1px solid black; */
    /* transform: rotate(45deg); */
    /* position: absolute; */
    /* left: 5px; */
    /* top: 0%;*/
}

.carousel_modal .custom-button-prev::before {
    top: 0px;
    left: -5%;
    font-size: 1rem;
    position: absolute;
    font-family: 'silkamono-light';
}

.carousel_modal .custom-button-next::before {
    top: 0px;
    right: -5%;
    font-size: 1rem;
    position: absolute;
    font-family: 'silkamono-light';
}

.landscape {
    height: 300px;
}

.portrait {
    height: 540px;
}

.box_image .img-carousel {
    height: 90%;
    width: 99%;
    object-fit: contain;
}

.box_image .current_index {
    width: 90px;
    position: absolute;
    padding: 0;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    font-family: "silkamono-regular";
}

@media (min-width: 768px) {
    .landscape .img-carousel {
        height: 99%;
    }

    .portrait .img-carousel {
        height: 98%;
    }

    .landscape {
        height: 500px;
    }
    
    .portrait {
        height: 600px;
    }
}

@media (min-width: 1200px) {
    .landscape {
        height: 300px;
    }
    
    .portrait {
        height: 500px;
    }

    .carousel_news .carousel-button-group {
        position: absolute;
        top: 45%;
        transform: translate(-50%, -50%);
        width: 98%;
        left: 50%;
        z-index: 9;
    }

    .custom-button-next::before {
        right: 2px;
    }
    
    .custom-button-prev::before {
        left: 2px;
    }

}

@media (min-width: 1535px) {
    .box_image {
        height: 540px;
    }
}

@media (min-width: 2000px) {
	.box_image {
        height: 840px;
    }

    .box_image .img-carousel {
        height: 90%;
    }

    .custom-button-next::before {
        height: 20px;
        width: 20px;
    }
    
    .custom-button-prev::before {
        height: 20px;
        width: 20px;
    }
}