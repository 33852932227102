

/* -------------------------------------------------Header side-nav-------------------------------------------- */


.content-header {
	z-index: 10;
	background-color: transparent;
	width: 15vw;
	position: fixed;
	top : 0;
	bottom: 0;
}

.header_1 {
	display: inline-block;
	color: black;
	cursor: pointer;
}

.header_1 span {
	display: block;
	width: 30px;
	margin: 8px 0px 0px 0px;
	height: 1px;
	background: black;
	cursor: pointer;
    /* padding: 0.5px 0px 0px 0px; */
}

.header_1 {
	background: #fff;
	position: absolute;
	left: 50%;
	top: 50%;
	color: #fff;
	transform: translate(-50%, -50%);
}

.sidebar-menu {
	background-color: white;
	height: 100%;
	transform: translateX(-100%);
	transition: 0.5s;
	display: flex;
}

.sidebar-menu.active {
	transform: translateX(0);
	transition: 0.5s;
}

.sidebar-menu-tabs {
	align-self: center;
	padding-left: 7%;
	width: 100%;
}

.sidebar-menu-tabs .nav-link {
	cursor: pointer;
	font-size: 0.9rem;
	padding: 1rem 1rem 1rem 0px;
	width: 80%;
}

.main_nav {
	display: flex;
	position: relative;
	width: 100%;
	background: #fff;
	height: 100vh;
	transition: all 0.5s ease-in-out;
	transform: translateX(0%);
	background-color: white;
}

.nav_close_icon {
	cursor: pointer;
    width: 30px;
    height: 30px;
    opacity: 1;
    right: 5%;
    position: absolute;
    transform: translate(0%, -50%);
    top: 50%;
}

.nav_close_icon:hover {
    opacity: 1;
}

.nav_close_icon:before, .nav_close_icon:after {
	position: absolute;
    left: 50%;
    content: ' ';
    height: 30px;
    width: 1px;
    background-color: #333;
}

.nav_close_icon:before {
    transform: rotate(45deg);
}

.nav_close_icon:after {
    transform: rotate(-45deg);
}

.main_nav li {
  	margin: 0;
	padding-top: 12px;
	display: block;
	text-decoration: none;
	color: black;
	transition: all 0.3s ease-in-out;
	font-family: 'Silka Mono Light';
}

#nav_trigger {
	opacity: 0;
	width: 0;
	height: 0;
	margin: 0;
	padding: 0;
	position: absolute;
}

#nav_trigger:checked + .main_nav {
	transform: translateX(-100%);
}

.logo {
	margin-top: 20px;
	margin-bottom: 20px;
}

.unScrollAfterHome{
	overflow: hidden;
}

.scrollAfterHome{
	overflow: scroll;
}

.nav_icon:active + .wrapper {
  	background-color: black;
}

.main_nav .nav {
	display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    margin-bottom: 0;
	list-style: none;
	align-self: center;
    margin: 0;
}

.flex-column {
	margin: auto;
	top: 0;
	bottom: 0;
}

.main_nav .nav-link {
	font-family: 'silkamono-light';
	font-style: normal;
	font-weight: 300;
}

.nav-link:active {
	text-decoration: underline;
}

.custom_cross {
    cursor: pointer;
    width: 30px;
    height: 30px;
    opacity: 1;
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 10;
}

.custom_cross:hover {
    opacity: 1;
}

.custom_cross:before, .custom_cross:after {
	position: absolute;
    left: 50%;
    content: ' ';
    height: 30px;
    width: 1px;
    background-color: #333;
}

.custom_cross:before {
    transform: rotate(45deg);
}

.custom_cross:after {
    transform: rotate(-45deg);
}

.show_social {
	display: block;
}

.hide_social {
	display: none;
}

.show_legal {
	display: block;
}

.hide_legal {
	display: none;
}

div.dropdown-item, a.dropdown-item {
	color: #8c8e93d1;
	cursor: pointer;
	font-family: "silkamono-light";
	font-size: 0.8rem;
	padding: 0.45rem 1.5rem;
}

div.dropdown-item:hover {
	background-color: white;
}

.dropdown-item:focus, .dropdown-item:hover {
	background-color: white;
}

/*--------------------------------------------------------Footer-----------------------------------------------------------------*/

.content-footer {
	/* padding-top: 10px; */
	padding-right: 9px;
	position: relative;
	background-color: white;
}

.search_footer {
    border-bottom: 1px solid;
    margin-left: 14px;
}

.search {
	font-size: 10px;
	font-weight: lighter;
}

.scout_footer {
	display: flex;
    justify-content: space-between;
    font-size: 12px;
    /* padding-top: 7px; */
    padding-left: 14px;
}

.scout {
	color: black;
	font-style: normal;
	/* font-weight: 700; */
	font-family: 'aktiv-grotesk', 'sans-serif';
	font-size: 0.9rem;
	padding-right: 2px;
	cursor: pointer;
}

.scout:hover {
    /* color: black; */
    text-decoration: underline;
}

.getscouted {
	color: black;
	font-style: normal;
	font-weight: 700;
	font-family: 'aktiv-grotesk', 'sans-serif';
	font-size: 0.9rem;
	padding-right: 2px;
	cursor: pointer;
}

.getscouted:hover {
    color: #000000;
    text-decoration: underline;
}

.logo_footer {
  	position: absolute;
	bottom: 10px;
	right: 10px;
}

.logo_footer1 {
	height: 20px;
}

.display_layer {
	display: block !important;
}

.layer {
	background-color: black;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    position: absolute;
    display: none;
	z-index: 9;
	cursor: pointer;
}

.scrolling {
	overflow: hidden;
}

/* ############################################## */
/* ############# DEVICE SCREEN SIZE ############# */
/* ############################################## */

/*
Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap because it is "mobile first"
*/

.scrollbar
{
	margin-left: 30px;
	float: left;
	height: 300px;
	width: 65px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px;
}



.scrollbarFocus p::-webkit-scrollbar {
	width: 10px;               /* width of the entire scrollbar */
}

.scrollbarFocus p::-webkit-scrollbar-track {
	/*	background: white;        !* color of the tracking area *!
        background-color: white;*/
}

.scrollbarFocus p::-webkit-scrollbar-thumb {
	/*	background-color: black;    !* color of the scroll thumb *!
        border-radius: 20px;       !* roundness of the scroll thumb *!
        border: 0px solid black;  !* creates padding around scroll thumb *!*/
}

#grid_news {
	padding: 10px 12px;
}

#grid_news > div {
	margin-bottom: 15px;
}

.wrapper, .wrapper_modalOpen {
	grid-template-columns: repeat(2, 1fr);
    width: 100%;
	display: grid;
	/* height: 100%; */
	grid-template-rows: auto;
	grid-auto-flow: row dense;
	width: 80vw;
}

.carousel {
	grid-column: auto / span 2;
}

.video {
	grid-column: auto / span 2;
	padding: 0 2px;
}

.video video {
	width: 100%;
}

.focus {
	grid-column: auto / span 2;
}

.focus {
	border-right: 1px solid black;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 0px solid black;
}

.article {
	grid-column: auto / span 2;
}

.post_landscape {
	grid-column: auto / span 2;
}

.post_portrait {
	grid-column: auto / span 1;
}

.whitespace {
	display: none;
}

.carousel_two_col, .article_two_col, .video_two_col {
	grid-column: auto / span 2;
	border: 1px solid black;
}

.noscroll {
	overflow: hidden;
}

.carousel, .focus, .article {
	grid-column: auto / span 2;
	border: 1px solid black;
}

.carousel > .row > h2, .article > .row > h2, .focus > .row > h2 {
	border-bottom: 1px solid;
	text-align: center;
	margin: 0;
	width: 100%;
	padding: 5px;
    font-size: 14px;
    font-family: 'silkamono-regular';
}

/* .carousel_two_col, .article_two_col, .video_two_col {
	grid-column: auto / span 2;
	border: 1px solid black;
} */

.carousel .middle_content {
	border-bottom: 1px solid;
	width: 100%;
}

.carousel .middle_content .lower_content {
	padding: 10px 20px;
}

.carousel .content_component {
	width: 100%;
	display: block;
}

.content-box {
	display: flex;
	position: relative;
}

.content-footer {
	position: relative;
}

.content-wrapper {
	padding-left: 15.1vw;
	flex: 5 1;
	overflow: hidden;
	padding-bottom: 3px;
}

.carousel .lower_content > .row, .focus .lower_content > .row, .article .lower_content > .row {
	height: 100%;
}

.carousel .content_component .lower_content p {
	height: 100%;
	max-height: 200px;
	font-size: 0.8rem;
}

.focus .content_component .lower_content p {
	height: 100%;
	max-height: 350px;
}

.article .content_component .lower_content p {
	height: 100%;
	max-height: 400px;
	font-size: 0.9rem;
    line-height: 1.6rem;
}

.disabled {
	pointer-events: none;
}

.wrapper::-webkit-scrollbar, .wrapper_modalOpen::-webkit-scrollbar {
	display: none; /* safari et chrome askip */
}

.wrapper .box-image .image, .wrapper_modalOpen .image {
	width: 100%;
}

.wrapper_modalOpen .box-image {
	/* align-self: center; */
	padding: 0px 2px;
}

.wrapper .box-image {
	/* align-self: center; */
	padding: 0px 2px;
}

.box-image .image:hover {
	background-color: white;
    opacity: 0.8;
	transition: 0.3s;
}

.content_component .lower_content > .row p::-webkit-scrollbar {
	width: 10px;
	margin: 10px;
}

.content_component .lower_content > .row p::-webkit-scrollbar-track {
	box-shadow: inset 0 0 7px grey;
	border-radius: 10px;
}

.content_component .lower_content > .row p::-webkit-scrollbar-thumb {
	background: #8c93a5;
	border-radius: 10px;
}

#grid_news .content_component {
	width: 100%;
	display: block;
}

.component_date {
	text-align: right;
}

.content_component .upper_content, .content_component .lower_content {
	padding: 5px 10px;
}

.upper_content, .lower_content {
	padding: 10px;
}

.lower_content h2 {
    font-size: 1.2rem;
    font-family: 'silkamono-light';
    margin-bottom: 1rem;
    width: 100%;
    height: min-content;
    font-weight: 300;
}

.upper_content p {
	font-family: 'silkamono-light';
	margin: 0;
	font-size: 12px;
}

.lower_content p {
	text-align: justify;
	font-family: 'aktiv-grotesk';
	font-weight: 300;
	margin: 0;
	height: 80%;
	overflow: auto;
	padding-right: 5px;
	line-height: 1.7rem;
}

.focus video {
	width: 100%;
}

.focus video:focus {
	outline: none;
}

.focus .middle_content {
	border-bottom: 1px solid;
	width: 100%;
}

.focus .video_div {
	padding: 0;
	display: flex;
}

.focus .middle_content .lower_content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 5px;
}

.content-footer {
	display: none;
}

.header-row {
	display: none;
}

.scrolldown {
	display: none;
}

.nav-link1 {
	display: none;
}

.main_nav {
	width: 100vw;
}

.nav_icon span {
	width: 20px;
}

.logo_first {
	width: 100%;
	position: absolute;
}

.logo_footer_first {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 200px;
	padding: 20px;
}

.logo {
	display: none;
}

.carousel {
	width: 100%;
}

.main_nav .nav-link {
    color: black;
    padding: 10px 0px;
    font-size: 25px;
    cursor: pointer;
}

hr {
    width: 40vw;
    margin-top: 3rem;
    margin-bottom: 3rem;
    background-color: black;
    border-top: 1px solid;
    margin: 0;
}

.video_header {
	display: none;
}

.video_header_tablette {
	display: none;
}
.modal-tab.active {
	transform: translateX(0vw);
}

.modal-tab {
	width: 100vw;
}

.text {
    transform: translate(0%, 16px);
}

.sidebar-menu {
    width: 100vw;
}

hr {
    width: 60vw;
}

.react-cookie-consent {
    background: white !important;
    color: black !important;
    width: 69.8% !important;
    opacity: 0.8;
    margin-left: 15.1vw;
    margin-right: 15.1vw;
    border: 1px solid black;
}

.separation {
	margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
	border-top: 1px solid black;
	width: 60%;
}

.scout1 {
	padding: 6px 0px;
}

.logo_footer2 {
	height: 40px;
	width: 30px;
}

.model-card{
	max-width: 100%;
	/*
	padding-left: 5px;
	*/
	position: relative;
	box-sizing: border-box;
}

.model-card__text{
	font-size: 15px;
	line-height: 18.85px;
	letter-spacing: 0.1em;
	font-weight: 400;
	transform-origin: bottom left;
	transform: rotate(-90deg);
	display: inline-block;
	position: absolute;
	top: 320px;
	width: 335.333px;
	text-align: right;
	transition: 0.5s;
	opacity: 0;
	left: 20px;
	z-index: 20;
	text-overflow: ellipsis;
}

.model-card:hover .model-card__text{
	opacity: 1;
	left: 1px;
}

.video-card{
	max-width: 100%;
	/*
	padding-left: 5px;
	*/
	position: relative;
	box-sizing: border-box;
}

.video-card__text{
	font-size: 15px;
	line-height: 18.85px;
	letter-spacing: 0.1em;
	font-weight: 400;
	transform-origin: bottom left;
	transform: rotate(-90deg);
	display: inline-block;
	position: absolute;
	top: 203px;
	width: 220px;
	text-align: right;
	transition: 0.5s;
	opacity: 0;
	left: 20px;
	z-index: 20;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.video-card:hover .video-card__text{
	opacity: 1;
	left: 1px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	body {
		overflow: scroll;
	}

	.carousel_two_col > .row > h2, .article_two_col > .row > h2, .video_two_col > .row > h2 {
		border-bottom: 1px solid;
		text-align: center;
		/* text-transform: uppercase; */
		margin: 0;
		width: 100%;
	}

	hr {
		width: 40vw;
		margin-top: 3rem;
		margin-bottom: 3rem;
		background-color: black;
		border-top: 1px solid;
	}

	.video_header {
		display: none;
	}

	.video_header_tablette {
		display: none;
	}

	.focus .middle_content {
		border-bottom: none;
		border-right: 1px solid;
	}

	.modal-tab.active {
		transform: translateX(0vw);
	}

 	.modal-tab {
    	width: 100vw;
	}
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

	body {
		overflow: scroll;
	}
	.nav-link1 {
		display: block;
	}

	.main_nav {
		width: 45vw;
	}

	hr {
		width: 40vw;
		margin-top: 3rem;
		margin-bottom: 3rem;
		/* border: 1; */
		background-color: black;
		border-top: 1px solid;
	}

	.video_header {
		display: none;
	}

	.video_header_mobile {
		display: none;
	}

	.video_header_tablette {
		display: block;
	}

	.modal-tab.active {
		transform: translateX(34.5vw);
	}

	.modal-tab {
		width: 65.6vw;
		height: 100vh;
	}

	.sidebar-menu {
    	width: 35vw;
	}

	hr {
		width: 20vw;
	}
}

/* (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	body {
		overflow: scroll;
	}

	#grid_news > div {
		margin-bottom: 0px;
	}

	.wrapper, .wrapper_modalOpen {
		/* padding: 5px 5px 5px 5px; */
		padding: 5px 0px;
		display: grid;
		grid-gap: 15px;
		overflow: auto;
		height: 100%;
		grid-template-rows: auto;
		/* grid-auto-flow: row dense; */
		/* grid-auto-rows: minmax(150px, auto); */

		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
	}

	.wrapper {
		grid-template-columns: repeat(4, 1fr);
		width: 100%;
	}

	.wrapper_modalOpen {
		grid-template-columns: repeat(2, 1fr);
	}	

	.carousel {
		grid-column: auto / span 4;
	}

	.focus {
		grid-column: auto / span 3;
	}

	.article {
		grid-column: auto / span 2;
	}

	.carousel_two_col, .article_two_col, .video_two_col {
		grid-column: auto / span 2;
	}

	/* .header_1 span {
		height: 2px;
	} */

	.content_component .lower_content > .row::-webkit-scrollbar {
		width: 10px;
		margin: 10px;
	}

	.content_component .lower_content > .row::-webkit-scrollbar-track {
		box-shadow: inset 0 0 7px grey;
		border-radius: 10px;
	}

	.content_component .lower_content > .row::-webkit-scrollbar-thumb {
		background: #8c93a5;
		border-radius: 10px;
	}

	/* .whitespace {
		display: block;
	}

	.whitespace {
		grid-column: auto / span 1;
		width: 100%;
		height: 100%;
	} */

	.content-wrapper {
		/* padding-left: 15.1vw; */
		flex: 5;
		/* overflow: hidden; */
		height: 100vh;
		/* padding-bottom: 3px; */
	}

	.wrapper_modalOpen {
		grid-template-columns: repeat(2, 1fr);
		width: calc(100% - 25.5vw);
	}

	/* .carousel_two_col, .article_two_col, .video_two_col {
		grid-column: auto / span 2;
		border: 1px solid black;
	} */

	.carousel > .row > h2, .article > .row > h2, .focus > .row > h2 {
		border-bottom: none;
		text-align: unset;
		/* text-transform: uppercase; */
		/* margin: 0;
		width: 100%; */
		font-size: 2rem;
		padding: 0px;
	}

	.carousel_two_col > .row > h2, .article_two_col > .row > h2, .video_two_col > .row > h2 {
		border-bottom: 1px solid;
		text-align: center;
		font-size: 1rem;
		font-family: 'silkamono-regular';
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.carousel .middle_content {
		border-bottom: none;
	}

	.carousel_two_col .middle_content {
		border-bottom: 1px solid;
		width: 100%;
	}

	.carousel_two_col .content_component {
		width: 100%;
	}

	.print_array .middle_content {
		border-right: 1px solid;
		width: 70%;
	}

	.print_array {
		border: 1px solid black;
		display: flex;
	}

	.print_array > .row > h2 {
		position: relative;
		width: 50px;
		margin: 0;
		border-left: 1px solid;
		transform: rotate(180deg);
	}

	.print_array > .row > h2 span {
		writing-mode: vertical-rl;
		position: absolute;
		left: 50%;
		font-size: 12px;
		top: 50%;
		font-family: 'silkamono-regular';
		transform: translate(-50%, -50%);
	}

	.focus .middle_content {
		width: 100%;
	}

	.content-footer {
		display: block;
		width: 15vw;
	}

	.header-row {
		display: block;
	}

	.scrolldown {
		display: block;
	}

	.nav-link1 {
		display: block;
	}

	.main_nav {
		width: 15vw;
	}

	.content-header {
		position: relative;
		background-color: white;
	}

	.nav_icon span {
		width: 30px;
	}

	.logo_first {
		display: none;
	}

	.logo_footer_first {
		display: none;
	}

	.main_nav .nav-link {
		padding: 5px 0px;
		font-size: 14px;
	}

	hr {
		display: none;
	}

	.logo {
		display: block;
	}

	.content-wrapper {
		padding-left: 0px;
	}
	.video_header {
		display: block;
	}

	.video_header_mobile {
		display: none;
	}

	.video_header_tablette {
		display: none;
	}

	.separation {
		display: none;
	}

	.modal-tab.active {
        transform: translateX(14.9vw);
	}

	.modal-tab {
    	width: 50vw;
	}

	.sidebar-menu {
	    width: 15vw;
	}

	.sidebar-menu-tabs .nav-link {
		padding: 0.5rem 1rem 0.5rem 0px;
		width: 100%;
	}

	#hamburger {
		display: none;
	}

	.content_component .upper_content {
		padding: 10px;
	}

	.content_component .lower_content {
		padding: 1rem 1.5rem;
	}
}

/* Style when an image clicked */
.image_visible {
	grid-column: unset !important;
	grid-row: unset !important;
	align-self: unset !important;
}

/* Extra large devices (large desktops, 1535px and up) */
@media (min-width: 1535px) {
	body {
		overflow: scroll;
	}

	.wrapper {
		grid-template-columns: repeat(6, 1fr);
		width: 100%;
		padding: 5px 0px;
	}

	.carousel {
		grid-column: auto / span 6;
	}

	.article {
		grid-column: auto / span 2;
	}

	.focus {
		grid-column: auto / span 4;
	}

	.wrapper_modalOpen {
		grid-template-columns: repeat(4, 1fr);
		width: calc(100% - 25.5vw);
		padding: 5px 0px;
	}

	.article_two_col {
		grid-column: auto / span 3;
		border: 1px solid black;
	}

	.carousel_two_col, .video_two_col {
		grid-column: auto / span 4;
		border: 1px solid black;
	}

	.carousel .middle_content .lower_content {
		padding: 10px 30px;
	}

	/* .content-footer {
		display: block;
		width: 15vw;
	} */

	.header-row {
		display: block;
	}

	.scrolldown {
		display: block;
	}

	.nav-link1 {
		display: block;
	}

	.main_nav {
		width: 15vw;
	}

	.logo_first {
		display: none;
	}

	.logo_footer_first {
		display: none;
	}

	hr {
		display: none;
	}

	.logo {
		display: block;
	}

	.video_header {
		display: block;
	}

	.video_header_mobile {
		display: none;
	}

	.video_header_tablette {
		display: none;
	}

	.sidebar-menu {
		width: 15vw;
	}

	.modal-tab.active {
		transform: translateX(14.9vw);
	}
}

@media (min-width: 2000px) {
	body {
		overflow: scroll;
	}

	.logo_footer1 {
		height: 25px;
	}

	.header_1 span {
		width: 40px;
	}

	.focus {
		grid-column: auto / span 3;
	}

	.carousel .middle_content .lower_content {
		padding: 10px 40px;
	}

	.article_two_col {
		grid-column: auto / span 2;
	}

	.video_two_col {
		border: 0px;
	}

	.video_two_col > .row:nth-child(1) {
		border: 1px solid;
		border-bottom: 0px;
	}

	.video_two_col > .row:nth-child(2) {
		border: 1px solid;
		border-top: 0px;
	}

	.focus .content_component .lower_content p {
		max-height: 360px;
	}

	.lower_content h2 {
		font-weight: 600;
	}
}