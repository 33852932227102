/* ############################################## */
/* ############# DEVICE SCREEN SIZE ############# */
/* ############################################## */
/*
Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap because it is "mobile first"
*/

.modal-tab {
	/* position: absolute; */
	width: 60vw;
	top: 0;
	/* height: 100vh; */
	z-index: 10;
	transition: 0.3s;
	transform: translateX(-100%);
	position: fixed;
    height: 100%;
}

.content_tab {
	background-color: white;
	position: relative;
	height: 100%;
}

/* .modal-tab.active {
	transform: translateX(14.9vw);
} */

.modal-tab {
	width: 100vw;
}

.close_tab {
	cursor: pointer;
	z-index: 10;
	position: absolute;
	top: 49%;
	right: -2px;
	width: 20px;
	height: 20px;
	border-left: 1px solid black;
	border-bottom: 1px solid black;
	transform: rotate(45deg);
}

.modal-tab .content_tab {
	padding: 3vh 4vh 3vh 2vh;
}

.modal-tab .contact_tab {
	display: flex;
	align-content: center;
}

.modal-tab .array_tab {
	border: 1px solid black;
	padding: 0px;
	display: flex;
	padding: 0px 10px 0px 0px;
}

.array_tab > .row > h2 {
	position: relative;
	width: 50px;
	margin: 0;
	border-left: 1px solid;
	transform: rotate(180deg);
}

.array_tab > .row > h2 span {
	text-transform: uppercase;
	font-family: "silkamono-light";
	/* font-family: "silkamono-regular"; */
	writing-mode: vertical-rl;
	position: absolute;
	left: 50%;
	font-size: 13px;
	font-weight: 400;
	top: 50%;
	transform: translate(-50%, -50%);
}

.tab2columns {
	-webkit-column-count: 2; /* Chrome, Safari, Opera */
	-moz-column-count:    2; /* Firefox */
	column-count:         2;
	-webkit-column-gap:   20px; /* Chrome, Safari, Opera */
	-moz-column-gap:      20px; /* Firefox */
	column-gap:           20px;
	/*column-rule-color: #ccc; !* Optional *!
	column-rule-style:solid; !* Optional *!
	column-rule-width: 1px; !* Optional *!*/
	text-align: justify; /* Optional */
}

.tab3columns {
	   -webkit-column-count: 3; /* Chrome, Safari, Opera */
	   -moz-column-count:    3; /* Firefox */
	   column-count:         3;
	   -webkit-column-gap:   20px; /* Chrome, Safari, Opera */
	   -moz-column-gap:      20px; /* Firefox */
	   column-gap:           20px;
	   column-rule-color: #ccc; /* Optional */
	   column-rule-style:solid; /* Optional */
	   column-rule-width: 1px; /* Optional */
	   text-align: justify; /* Optional */
   }


.array_tab .download_tab {
	padding: 5%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.array_tab .scroll_form .download_tab {
	padding: 0%;
}

.array_tab .scroll_form {
	padding: 2%;
	padding-left: calc(2% + 10px);
	justify-content: space-evenly;
}

#root .array_tab .scroll_form {
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100%;
}

.download_tab h2 {
	text-align: center;
	font-family: 'silkamono-regular';
	font-size: 1rem;
	margin: revert;
}
.about_tab h2{
	font-size: 20px;
	padding: 15px;
}
.download_tab p {
	font-family: 'aktiv-grotesk';
	font-style: italic;
	font-size: 0.8rem;
}

.download_tab .content_form input {
	margin-bottom: 1rem;
	padding: 5px;
	z-index: 1;
}

.scroll_form .content_form input {
	padding: 5px;
    height: 38px;
	z-index: auto;
	color: black;
    font-family: 'aktiv-grotesk';
    font-weight: 400;
    font-size: 0.9rem;
	margin-bottom: 0.2rem;
}

#form-download .round label {
	left: 1.8px;
	top: 20px;
}

.download_tab .content_form select, .scroll_form .content_form select{
	margin-bottom: 1.4rem;
	padding: 5px 0px;
	cursor: pointer;
}

.download_captcha {
	text-align: center;
	margin: 30px 0px;
}

.download_captcha > div > div {
	width: auto !important;
}

.email_send {
	display: flex;
}

.email_send_box {
	position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.email_send_box img {
	width: 35px;
}

.email_send h2 {
	font-size: 1.4rem;
	padding: 10px 15%;
	font-family: 'silkamono-light';
}

.content_form select, .content_form input, .scroll_form select, .scroll_form input , .scroll_form .css-2b097c-container{
	width: 100%;
	border: none;
	border-bottom: 1px solid black;
	padding-bottom: 0px;
	margin-bottom: 5px;
	font-size: 13px;
}

.content_form select, .content_form input::placeholder, .scroll_form .css-2b097c-container {
	color: black;
	font-family: 'aktiv-grotesk';
}

.content_form select:focus, .content_form input:focus {
	outline: none;
}

.content_form button {
	width: 100%;
	height: auto;
	font-size: 15px;
	font-family: 'aktiv-grotesk';
	font-weight: 400;
	background-color: white;
	border: 1px solid black;
	padding: 10px;
}

.content_form .form_checkbox {
	width: auto;
	margin: 0px;
	margin-left: 10px;
}

.contact_tab h2 {
	font-size: 20px;
	font-family: 'silkamono-light';
}

.contact_tab .array_tab h3 {
	font-family: 'aktiv-grotesk';
	font-weight: 700;
	font-size: 11px;
	margin: 0px 0px 10px 0px;
}

.contact_tab .array_tab {
	padding: 0;
}

.contact_tab .array_tab p {
	font-family: 'aktiv-grotesk';
	font-size: 12px;
	margin: 0;
}

.contact_tab a, .contact_tab a:hover {
	color: black;
}

.contact_tab a {
	font-size: 13px;
	font-family: 'aktiv-grotesk';
}

.info_contact {
	font-weight: bold;
	text-decoration: underline;
}

.array_tab .contact_address {
	padding: 10px 10px 0px 10px;
}

.array_tab .contact_address p {
	font-family: 'silkamono-light';
	margin: 0px;
}

.contact_tab .array_tab .box_information1 {
	border-bottom: 1px solid;
}

.contact_tab .array_tab .box_information1, .contact_tab .array_tab .box_information {
	padding: 10px;
}

.hasBorder {
	outline: 1px solid red;
}

.content_form textarea {
	resize: none;
	width: 100%;
	height: 110px;
	z-index: 0;
}

.content_form textarea:focus {
	outline: none;
	border-radius: unset;
}

.showDemand {
	cursor: pointer;
}

.showDemand:hover {
	opacity: 0.5;
}

.content_form {
	position: relative;
}

.content_form input, .content_form textarea {
	position: relative;
	background: transparent;
}

.content_form label {
	position: absolute;
	left: 7px;
	top: 10px;
	z-index: 0;
}

#form-contact label {
	position: absolute;
    left: 20px;
    top: 10px;
    z-index: 0;
    margin: 0;
}

#form-download label {
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 0;
}

.content_form label::after {
	content: "*";
	color:red;
}

input[required]:valid + label, textarea[required]:valid + label {
	display: none;
}

.content_form .react-tel-input .form-control {
	padding-left: 48px;
}

/*-------------------code Oumaima------------------------*/

.row .scroll_form {
	overflow: auto;
}

.about_tab, .contact_scroll {
	overflow: auto;
	height: 100%;
}

.array_tab .download_scroll {
	width: 100%;
	margin-top: 10px !important;
    margin-bottom: 10px !important;
    overflow-x: hidden;
    overflow-y: auto;
}

a#legal{
	color: black;
	text-decoration: underline;
}

.scroll_form .content_form .input_index {
	z-index: 1;
}

.modal-tab .array_about {
	border: 0px;
	border-left: 1px solid black;
}

.array_about > .row > h2 span {
	text-transform: uppercase;
	font-family: "silkamono-extraLight";
	writing-mode: vertical-rl;
	position: absolute;
	left: 50%;
	font-size: 13px;
	top: 50%;
	transform: rotate(180deg) translate(50%, 50%);
}

.array_tab p{
	font-size: 0.8rem;
	text-align: justify;
}

.array_tab .para_file {
	font-size: 12px;
	width:140px;
	overflow:hidden;
}

.array_tab .scroll_form .para p{
	float: right;
	font-size: 0.7rem;
	margin: 30px 0px 10px 0px;
}

.submit {
	font-family: 'aktiv-grotesk', 'sans-serif';
	font-style: normal;
	font-weight: 400;
	float:none;
	margin:auto;
}

.text p, .polas p {
	padding-top: 5px;
	padding-bottom: 15px;
	font-family: 'aktiv-grotesk', 'sans-serif';
	font-style: normal;
	font-weight: 300;
	color: grey;
	margin:auto;
	float:none;
	text-align: center;
}

.text p {
	font-size: 15px;
	/* position: absolute; */
}

.array_tab .round1 p{
	padding-top: 20px;
}

.round label {
	background-color: #fff;
	border: 1px solid black;
	border-radius: 50%;
	cursor: pointer;
	height: 15px;
	position: relative;
	width: 15px;
	float: left;
}

.round label:after {
	border: 2px solid #fff;
	border-top: none;
	border-right: none;
	content: "";
	height: 6px;
	left: 1.8px;
	opacity: 0;
	position: absolute;
	top: 20%;
	transform: rotate(-45deg);
	width: 10px;
}

.round input[type="checkbox"] {
	visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
	background-color: black;
	border-color: black;
}

.round input[type="checkbox"]:checked + label:after {
	opacity: 1;
}

.text3{
	font-size: 10px;
	text-align: justify;
}

.video_about{
	width: 100%;
}

.row  .mesure{
	width: 100%;
}

.content_form .phone_container .phone_input {
	border: none;
	color: black;
	border-bottom: 1px solid;
	border-radius: unset;
	margin: 0;
	width: 100%;
	margin-bottom: 0.2rem !important;
}

.content_form .phone_container .flag-dropdown {
	border: none;
	border-bottom: 1px solid black;
	background-color: white;
	border-radius: unset;
	z-index: 0;
}

.react-tel-input .country-list {
	width: 227px !important;
}

.content_form .phone_container .phone_input:focus {
	box-shadow: none;
}
/* width */
.scroll_form::-webkit-scrollbar, .about_tab::-webkit-scrollbar, .download_scroll::-webkit-scrollbar, .contact_scroll::-webkit-scrollbar {
	width: 10px;
	margin: 10px;
}

/* Track */
.scroll_form::-webkit-scrollbar-track, .about_tab::-webkit-scrollbar-track, .download_scroll::-webkit-scrollbar-track, .contact_scroll::-webkit-scrollbar-track {
	box-shadow: inset 0 0 7px grey;
	border-radius: 10px;
}

/* Handle */
.scroll_form::-webkit-scrollbar-thumb, .about_tab::-webkit-scrollbar-thumb, .download_scroll::-webkit-scrollbar-thumb, .contact_scroll::-webkit-scrollbar-thumb {
	background: #8c93a5;
	border-radius: 10px;
}

/* Handle on hover */
/* .scroll_form::-webkit-scrollbar-thumb:hover {
background: black;
} */

.array_tab .legal_tab {
	padding: 0px;
}

.legal_tab h1 {
	font-family: 'silkamono-regular';
	font-size: 0.9rem;
	margin-bottom: 2rem;
	text-align: center;
}

.legal_tab h2 {
	font-family: 'aktiv-grotesk';
	font-size: 0.9rem;
}

.legal_tab p {
	font-family: 'aktiv-grotesk';
	font-weight: 300;
	font-size: 0.9rem;
	text-align: justify;
}

.legal_tab img, .about_tab img {
	width: 100%;
}

.about_tab h1 {
	font-family: 'silkamono-regular';
	font-size: 1.5rem;
	text-align: center;
	margin-bottom: 2rem;
}

.content_form .custom-file-upload {
	border: 1px solid black;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	width: 100%;
	overflow: hidden;
	height: 50px;
	line-height: 50px;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 0px 8px;
	position: unset;
}

#upload-profile{
	display:none;
}

#upload-fbody{
	display:none;
}

#upload-portrait{
	display:none;
}

.about_tab p {
	font-size: 0.9rem;
	text-align: justify;
	font-family: "aktiv-grotesk";
	font-weight: 300;
	margin-bottom: 3rem;
	line-height: 1.7rem;
}

.about_tab video {
	width: 100%;
}

.css-2b097c-container .css-yk16xz-control {
	border-radius: block;
	border-style: solid;
	border-width: 0px;
	min-height: 38px;
	height: 38px;
}

.css-2b097c-container .css-1pahdxg-control {
	box-shadow: none;
	border: none;
	height: 38px;
}

.css-2b097c-container .css-1okebmr-indicatorSeparator {
    margin-bottom: 0px;
    margin-top: 0px;
    width: 0px;
}
/*
.css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer, .css-g1d714-ValueContainer .css-1wa3eu0-placeholder {
    color: black !important;
    font-family: 'aktiv-grotesk' !important;
    font-weight: 400 !important;
    font-size: 0.9rem !important;
} */

.css-g1d714-ValueContainer {
    padding: 0px 0px 0px 5px !important;
	cursor: pointer;
}

.css-b8ldur-Input {
	margin: 0px;
	padding-bottom: 0px;
	padding-top: 0px;
}

.filter:focus {
	box-shadow: none;
}

.css-1uccc91-singleValue {
	top: 70%;
}

/* .scroll_form .selectCountry {
	z-index: 0;
} */

.css-2b097c-container{
	border-bottom: 1px solid black;
	margin-bottom: 10px;
}

.css-2b097c-container input {
  	margin: 0px !important;
}

.css-1wa3eu0-placeholder, .content_form label {
    color: black;
    font-family: 'aktiv-grotesk';
    font-weight: 400;
    font-size: 0.9rem;
}

.download_tab .length-COMMENTAIRE {
	left: 89%;
	font-size: 11px;
}

.css-1wa3eu0-placeholder {
	font-size: 11px;
}

.no-padding-mobile {
	padding: 0px !important;
}

/* .true_download_tab {
	position: absolute;
    width: 100%;
} */

p.text_models_infos {
	font-family: 'aktiv-grotesk';
	font-style: italic;
	font-size: 0.8rem;
	margin-bottom: 0px;
	padding: 0 15px;
}

h2.text_models_infos {
	font-weight: 500;
	text-align: center;
	font-family: 'silkamono-regular';
	font-size: 1rem;
	margin-bottom: 2rem;
	margin-top: 1rem;
	transform: none!important;
	width: 250px!important;
	margin: revert!important;
	border: none!important;
	text-transform: uppercase!important;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	.css-1wa3eu0-placeholder {
		font-size: 11px;
	}
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
	.no-padding-mobile {
		padding: 0px 15px !important;
	}

	.css-1wa3eu0-placeholder {
		font-size: 11px;
	}

	.modal-tab {
		z-index: 9;
		width: 65.5vw;
	}

	.array_tab .download_tab {
		padding: 0% 5% 20%;
	}

	.contact_tab .array_tab h3 {
		font-size: 12px;
	}

	/* .contact_tab .array_tab .box_information1, .contact_tab .array_tab .box_information {
		padding: 10px;
	} */

	.contact_tab .array_tab .box_information1 {
		border-right: 1px solid black;
		border-bottom: 0px;
	}

	.no-padding .no-padding-left {
		padding-left: 0px;
	}

	.no-padding .no-padding-right {
		padding-right: 0px;
	}

	.para {
		padding-top: 30px;
	}

	.array_tab .round1 p{
		padding-top: 30px;
	}

	.content_form label {
		left: 20px;
		top: 10px;
	}
}

/* (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.css-1wa3eu0-placeholder {
		font-size: 13px;
	}

	.modal-tab {
		position: absolute;
		/* width: 60vw; */
		top: 0;
		/* height: 100vh; */
		/* z-index: 10; */
		/* transition: 0.3s;
		transform: translateX(-100%); */
	}

	.modal-tab .content_tab {
		padding: 7vh 7vh 7vh 1vh;
	}

	.array_tab > .row > h2 span {
		font-weight: 400;
		font-family: 'silkamono-light';
		font-size: 13px;
	}

	.close_tab {
		right: 15px;
	}

	.contact_tab .array_tab .box_information1 {
		border-bottom: 0px;
	}
}

/* Extra large devices (large desktops, 1535px and up) */
@media (min-width: 1535px) {
	.css-1wa3eu0-placeholder {
		font-size: 13px;
	}

	.modal-tab {
		z-index: 9;
		width: 40vw;
	}
}

@media (min-width: 2000px) {
	.download_tab h2 {
		margin-bottom: 5rem;
	}

	.modal-tab {
		width: 35vw;
	}

	.modal-tab .content_tab {
		padding: 20% 7% 20% 1%;
	}

	.scout_form {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
