/* ############################################## */
/* ############# DEVICE SCREEN SIZE ############# */
/* ############################################## */
/*
Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap because it is "mobile first"
*/

.modal-img {
    position: fixed;
    /* width: 100vw; */
    width: 100%;
    top: 0;
    right: 0;
    /* height: 100vh; */
    height: 100%;
    z-index: 10;
    background-color: white;
    padding: 20px;
    transition: 0.3s;
    transform: translateX(+105%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
}

.modal-img.active {
    transform: translateX(0);
}

.modal-img .image img {
    width: 100%;
}

.modal_carousel {
    height: 100%;
}

.modal_carousel > div > p {
    border: 1px solid;
    max-width: 90%;
    text-align: right;
    float: right;
    padding: 0px 10px;
    font-family: "silkamono-regular";
}

.modal-text-zone {
    height: 10%;
}
.modal-carousel-zone {
    height: 90%;
}

.carousel_modal {
    height: 90%;
}

.modal-video {
    text-align: center;
}

.modal-video video {
    width: 80%;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .modal-video video {
        width: 50%;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

}

/* (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .modal-img {
        position: absolute;
        width: 39.6vw;
        border-left: 1px solid black;
        border-top: 1px solid black;
        z-index: 8;
    }
    .modal-video video {
        width: 80%;
    }
}

/* Extra large devices (large desktops, 1535px and up) */
@media (min-width: 1535px) {

}
