.header-top {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
}

.stop-scrolling {
	height: 100%;
	overflow: hidden;
}

/* -------------------------------------------------Header side-nav-------------------------------------------- */
.header-row {
	padding-top: 10px;
	width: 70%;
}

.header1 {
	height: 20px;
	padding-top: 5px;
	padding-left: 15px;
	padding-right: 15px;
}

.right-side:hover {
    color: #696969;
    text-decoration: none;
	opacity: 0.5;
}

.right-side {
	color: #696969;
	font-family: 'silkamono-light';
	font-style: normal;
	font-weight: 300;
	font-size: 13px;
	float: right;
	cursor: pointer;
}

.left-side {
	color: #696969;
	font-family: aktiv-grotesk, sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 13px;
	float: left;
}

.header-logo {
	height: 30px;
	position: relative;
}

.logo1 {
	height: 100%;
	width: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.nav-links {
	justify-content: center;
}

.nav-links .nav-link {
	margin: 0px 1rem;
	padding: 1rem 0rem;
	cursor: pointer;
}

.nav-link {
	font-size: 13px;
	color: #696969;
	font-family: 'silkamono-light';
	font-style: normal;
	font-weight: 300;
}

.nav-link:hover {
	color: #696969;
	text-decoration: none;
	opacity: 0.5;
}

.nav-link a:active {
	color: #696969;
}

/*-------------------------------------------------------------Initial Video--------------------------------------------------------*/
.page_header {
	position: relative;
	height: 98%;
	background-color: white;
	padding-top: 20px;
	/* JK margin-left: 15.1vw;*/
}

.page_header1 {
	height: 100%;
	width: 80vw;
	background-color: white;
	border: 1px solid black;
	display: flex;
	text-align: center;
}

.header-row a {
	text-decoration:none;
	cursor: pointer;
}

.video1 {
	width: 100%;
	padding: 11px;
}

.video1 .video_header, .video1 .video_header_tablette, .video1 .video_header_mobile {
	height: 100%;
	width: 100%;
	object-fit: cover;
	position: relative;
}

.title {
	font-family: 'silkamono-light';
	position: relative;
	width: 50px;
	border-right: 1px solid black;
}

.p {
	text-align: center;
}

.title  p span {
	  position: absolute;
    font-size: 10px;
    top: 50%;
    width: 51px;
    left: 70%;
    transform: rotate(-90deg);
    transform-origin: bottom left;
    text-transform: uppercase;
    font-family: 'aktiv-grotesk';
}

.scrolldown {
	display: block;
	margin: 10px 0px;
	width: 100px;
	position: relative;
	text-decoration:none;
}

.scroll {
	font-size: 13px;
	/*position: absolute;*/
	top: 100%;
	width: 100%;
	/* left: 30px;
	transform: translate(0px, -100%); */
}

.scrolldown .scroll p {
	text-decoration: none;
	font-family: 'silkamono-light';
	font-style: normal;
	font-weight: 300;
	color:black;
}

#scrolldown p {
	/*padding-left: 10px;
	margin: 0;*/
	cursor: pointer;
	width: 100px;
	text-align: center;
}

#scrolldown:hover {
	color: #696969;
	text-decoration: none;
}

.scrolldown span {
	cursor: pointer;
	display: block;
	position: relative;
	bottom: 20px;
    width: 19px;
    height: 19px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    transform: rotate(-45deg);
}

.scrolldown div:hover {
	opacity: 0.8;
}

@media (min-width: 0px) {
	.page_header {
		padding-left: 11.1vw;
	}

}

@media (min-width: 1200px) {
	.page_header {
		height: 83%;
		display: flex;
		padding-left: 0vw;
		padding-top: 0px;
	}

	.page_header1 {
		width: 70vw;
	}
}

@media (min-width: 1535px) {
}

@media (min-width: 2000px) {
	.header-logo {
		height: 50px;
	}

	.page_header {
		height: 88%;
	}
}
