#timeline {
    position: absolute;
    left: 5.9vw;
    z-index: 10;
    height: 100%;
    overflow: auto;
    pointer-events: none;
}

#timeline::-webkit-scrollbar {
	display: none;
}

#timeline h2 {
    /* width: 13px; */
    margin: 0;
    transform: rotate(180deg);
}

#timeline p:first-child {
    padding-bottom: 0px;
}

#timeline p {
	writing-mode: vertical-rl;
	text-orientation: sideways;
	margin: 0;
	font-size: 13px;
    padding: 280px 0;
    font-family: 'silkamono-regular';
}

#static-timeline {
    display: none;
}

@media (min-width: 576px) {
	
}

@media (min-width: 768px) {
	#timeline {
        left: 6.5vw;
    }

    #timeline p {
        padding: 430px 0;
    }
}

@media (min-width: 1200px) {
    #timeline {
        left: 11.4vw;
        z-index: 11;
    }

    #timeline p {
        padding: 280px 0;
    }

    #static-timeline {
        display: block;
    }

    #static-timeline {
        position: absolute;
        left: -3.6vw;
        z-index: 10;
        height: 100%;
        overflow: auto;
        pointer-events: none;
        top: 0;
    }

    #static-timeline::-webkit-scrollbar {
        display: none;
    }

    #static-timeline h2 {
        width: 13px;
        margin: 0;
        transform: rotate(180deg);
        padding: 110px 0px;
    }

    #static-timeline h2:first-child {
        padding-bottom: 0px;
    }

    #static-timeline p {
        writing-mode: vertical-rl;
        text-orientation: sideways;
        margin: 0;
        font-size: 13px;
        font-family: 'silkamono-regular';
    }
}

@media (min-width: 1535px) {

}

@media (min-width: 2000px) {
    #timeline p {
        width: 350px;
    }
}